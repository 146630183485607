// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
@import 'presets/grid-settings';

@media print {

  * {
    font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
  }

  body {
    color: black;
    background: white;
    padding: rem-calc(32px);
  }

  .homepage,
  .home,
  .subpage {
    .content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        page-break-after: avoid;
        // color: black;
        text-transform: none;
      }
    }

    p,
    ul,
    ol,
    blockquote {
      color: black;
    }

    img {
      max-width: 100% !important;
    }

    .video-container,
    .flex-video {
      display: none;
    }

    ul,
    img,
    blockquote,
    p {
      page-break-inside: avoid;
    }

    a {
      font-weight: bolder;
      text-decoration: none;
      color: #000;

      &:visited {
        color: #000;
      }
    }

    a[href^=http]:after {
      content: " (" attr(href) ") ";
    }

    a[href^="#"]:after {
      content: "";
    }

    @page  {
      margin: 2cm;
    }

    header.primary {
      background-color: transparent;
      height: auto;
      padding: 0;

      h1,
      h2 {
        @include fontsize(50 50);
        clip-path: none !important;
        position: relative !important;
        width: auto !important;
        height: auto !important;
        color: black;
        margin: 0 !important;
        overflow: visible !important;
      }
    }

    header.primary .contents {
      width: 100%;
      height: auto;
      position: relative;
      top: auto;
      padding: rem-calc(0 0 40px 0);
      left: auto;
    }

    header.primary .contents .logo,
    .nav-header,
    .mobile-header,
    #lrs-corp-bar,
    .rotating-banner,
    .tab-list,
    .mobile-tab,
    .sp-icn-container,
    .nav-primary,
    .breadcrumb-container,
    .sidebar,
    .back-to,
    .sharing,
    .cookie-popup,
    .blog-rotator,
    .blog-pager,
    a.skipcontent {
      display: none;
    }

    footer {
      display: none;
    }
  }

  .subpage h1 {
    font-size: 34px;
  }

  /* Accordions */

  .accordion-title button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }

  .accordion {
    .content {
      display: block !important;
      border-left: none;
      padding-left: 0;
    }

    .show-hide {
      display: none;
    }

    h3,
    .blurb {
      border-left: none;
      padding-left: 0;
    }
  }

    .tab-title,
    .accordion-title button {
      @include fontsize(23 26);
      letter-spacing: 0.0625em;
      font-weight: 400;
      color: #000;
      text-transform: none;
    }

    table {
      width: 100%;
      display: table;
      border-collapse: separate;
      border-spacing: 2px;
      border: 2px solid gray;
      text-align: center;

      td,
      th {
        border: 1px solid gray;
      }
    }
}

body.print {
  header h2 {
    @include fontsize(50 50);
    clip-path: none !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    color: black;
    margin: 0 !important;
    overflow: visible !important;
  }

  .call-to-action,
  .header-right,
  .header-links,
  img.logo,
  .hp-btn img {
    display: none;
  }
}

body.print.print-home .header-right {
  display: block;
}

body.kbd.subpage .drivve-search-options {
  display: none;
}

body.subpage.cloudprint {
  .free-trial,
  header,
  .icon-goodbye{
    display: none;
  }

  .content .intro,
  .subpage-banner {
    h2 {
      @include fontsize(50 50);
      clip-path: none !important;
      position: relative !important;
      width: auto !important;
      height: auto !important;
      color: black;
      margin: 0 !important;
      overflow: visible !important;
    }
  }
}

.cloudprint .three-block-column .col img {
  display: none !important;
}

.cloudprint-sub.cloudprint-sub-sidebar .sidebar {
  display: block !important;
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
